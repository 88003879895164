<template>
    <modal
        class="delete-memo-modal"
        :adaptive="true"
        name="delete-memo-modal"
        height="auto"
        width="550"
        @before-open="beforeOpen"
    >
        <div class="close-modal" @click="$modal.hide('delete-memo-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="content">
            <h1>Confirmation</h1>
            <p>Are you sure you want to delete this Memo?</p>
            <div class="actions ml-auto">
                <button type="button" class="btn btn-outline-primary" @click="$modal.hide('delete-memo-modal')">
                    Cancel
                </button>
                <button type="button" class="btn btn-danger ml-3" @click="deleteMemo">
                    Delete
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "DeleteMemo",
    data() {
        return {
            memodId: null
        };
    },
    methods: {
        beforeOpen(event) {
            if (event.params?.memodId) {
                this.memodId = event.params.memodId;
            } else {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: "Can't delete this memo, try again later",
                    duration: 3000
                });

                event.cancel();
            }
        },
        deleteMemo() {
            axiosV2.delete(`memos/${this.memodId}`).then(() => {
                if ((this.$route.name == "web-app-edit") && (this.$route.params.id == this.memodId)) {
                    this.$router.push({ name: "web-app-create" })
                }

                this.$modal.hide("delete-memo-modal");

                this.$notify({
                    group: "app-notifications",
                    type: "success",
                    text: "Delete Successfully",
                    duration: 3000
                });

                this.$emit("delete-memo", this.memodId);
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.delete-memo-modal {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal{
        background-color: #3A3A3C;
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 25px 20px;
            display: flex;
            flex-direction: column;

            h1 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
            }

            p {
                margin-top: 35px;
                margin-bottom: 45px;
                font-size: 18px;
                color: rgba(235, 235, 245, 0.6);
                text-align: center;
            }
        }
    }
}
</style>
